import {DeploymentType} from 'api/entities';
import {Brand, getThemingBrand} from './theming.static';

export const AvelonCloudBrand = 'Avelon Cloud';
export const AlcedoInhouseBrand = 'Alcedo Inhouse';

export const AvelonCloudHost = 'avelon.cloud';
export const AvelonCloudUrl = 'https://' + AvelonCloudHost;

export class BrandService {
  static getAvelonServerTitle(deploymentType: DeploymentType): string {
    return deploymentType === DeploymentType.CLOUD ? AvelonCloudBrand : AlcedoInhouseBrand;
  }

  static getPlatformTitle(deploymentType: DeploymentType): string {
    switch (getThemingBrand()) {
      case Brand.WAGO:
        return 'WAGO Cloud';
      default:
        return BrandService.getAvelonServerTitle(deploymentType);
    }
  }
}
